import * as React from 'react'
import { useGetList, FilterList, FilterListItem, useListContext } from 'react-admin'
import { Box, Chip } from '@mui/material'
import HomeWorkRoundedIcon from '@mui/icons-material/HomeWorkRounded'
import { filter } from 'lodash'

const HippocastListFilter = () => {
  const { data } = useGetList(
    'centers',
    {
      pagination: { page: 1, perPage: 50 },
      sort: { field: 'name', order: 'ASC' }
    }
  )

  // Set default filter when data is loaded and no filter is selected
  const { filterValues, setFilters } = useListContext()
  const hasDateSelected = filterValues['@and']?.['created_at@gte']

  React.useEffect(() => {
    if (data && !filterValues.center_id) {
      setFilters({ ...filterValues, center_id: process.env.REACT_APP_CENTER_GLOBAL }, undefined, true)
    }
  }, [data, filterValues, setFilters])

  React.useEffect(() => {
    // if the Global results are not showed
    // show global per center if no date selected
    if ( filterValues.center_id && filterValues.center_id !== process.env.REACT_APP_CENTER_GLOBAL ) {
      if ( ! hasDateSelected ) {
        setFilters({ ...filterValues, '@and': { 'created_at@gte': '2024-03-15' } }, undefined, true) // global for the center
      }
    } else {
      if ( hasDateSelected ) {
        setFilters({ ...filterValues,  'is_global': '1', 'is_monthly_global': '0' }, undefined, true)
      } else {
        setFilters({ ...filterValues, 'is_global': '1', 'is_monthly_global': '1' }, undefined, true) // global for the center
      }
    }
  }, [hasDateSelected, filterValues, setFilters])

  // Block the no-selection
  const isSelected = (value, filters) => {
    return filters.center_id === value.center_id
  }

  const toggleFilter = (value, filters) => {
    // If trying to deselect the current center, keep it selected
    if (filters.center_id === value.center_id) {
      return filters
    }

    // Otherwise, switch to the new center
    return {
      ...filters,
      center_id: value.center_id
    }
  }

  return (
    <Box width="15em" order="-1" marginRight="1em">
      <FilterList label="Centres" icon={<HomeWorkRoundedIcon />}>
        {data?.map(center => (
          <FilterListItem
            key={center.id}
            label={
              <Chip
                label={center.name}
                size="small"
                style={{
                  backgroundColor: center.color,
                  border: 0,
                  cursor: 'pointer'
                }}
              />
            }
            value={{ center_id: center.id }}
            isSelected={isSelected}
            toggleFilter={toggleFilter}
          />
        ))}
      </FilterList>
    </Box>
  )
}

export default HippocastListFilter