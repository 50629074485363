import { useState, useCallback, useMemo } from 'react'
import {
  FilterForm,
  SearchInput,
  AutocompleteInput,
  ReferenceInput,
  useListContext,
} from 'react-admin'
import { Checkbox, FormControlLabel, Stack } from '@mui/material'

const DashboardToolbar = ({ isMobile = false, isAdmin = false, withPaie = false, setWithPaie }) => {
  // Update filters when checkbox changes
  const handleWithPaieChange = useCallback((event) => {
    setWithPaie(event.target.checked)
  }, [setWithPaie])

  // Default Filters
  const DashboardFilter = [
    <SearchInput
      key="last_name"
      placeholder="Nom"
      source="last_name@ilike"
      variant="outlined"
      sx={{ maxWidth: '200px' }}
      alwaysOn
      resettable
    />,
    <SearchInput
      key="first_name"
      placeholder="Prénom"
      source="first_name@ilike"
      variant="outlined"
      sx={{ maxWidth: '200px' }}
      alwaysOn
      resettable
    />,
  ]

  if (isAdmin) {
    DashboardFilter.push(
      <ReferenceInput
        key="center"
        reference="centers"
        source="center_id"
        alwaysOn
      >
        <AutocompleteInput
          label="Centre"
          variant="outlined"
          optionText="name"
          filterToQuery={searchText => ({ 'name@ilike': searchText })}
        />
      </ReferenceInput>
    )
  }

  return (
    <Stack direction={isMobile ? 'column' : 'row'} style={{ padding: '0 10px' }}>
      <FilterForm filters={DashboardFilter} />
      <FormControlLabel
        control={
          <Checkbox
            checked={withPaie}
            onChange={handleWithPaieChange}
          />
        }
        label="Pubeurs avec paie uniquement"
        sx={{ marginLeft: 2 }}
      />
    </Stack>
  )
}

export default DashboardToolbar
