import { useEffect, useState, useCallback } from 'react'
import { SimpleForm, TextInput, useNotify, SaveButton, BooleanInput } from 'react-admin'
import { Box, Grid2, Stack, Typography } from '@mui/material'
import EuroIcon from '@mui/icons-material/Euro'

import { supabaseClient } from '../../supabase'
import { CardWithIcon } from '../../Design/Cards'

const PaieSettings = ({ value, isAdmin, identity }) => {
  const notify = useNotify()
  const [settings, setSettings] = useState({
    paieValidatedData: null,
    smicHoraire: null,
    smsData: null,
    createdContactsData: null,
  })

  const fetchFromDb = useCallback(async (table, options = {}) => {
    try {
      let query = supabaseClient
        .from(table)
        .select(options.select || '*')
  
      if (options.orderBy) {
        query = query.order(options.orderBy, { ascending: false })
      }
  
      if (options.eqField && options.eqValue) {
        query = query.eq(options.eqField, options.eqValue)
      }
  
      if (options.limit) {
        query = query.limit(options.limit)
      }
  
      const { data, error } = await query
  
      if (error) throw error
      
      return data
    } catch (error) {
      console.error(`Error fetching from ${table}:`, error.message)
      notify(`Une erreur est survenue lors de la récupération des données de ${table}`, { type: 'error' })
      return null
    }
  }, [notify])  

  const upsertData = useCallback(async (table, data, id = null) => {
    try {
      const { error } = await supabaseClient
        .from(table)
        [id ? 'update' : 'insert'](data)
        [id ? 'eq' : 'select'](...(id ? ['id', id] : []))

      if (error) {
        console.error(error)
      }

      return true
    } catch (error) {
      console.error(`Error ${id ? 'updating' : 'creating'} ${table}:`, error)
      notify(`Une erreur est survenue lors de la mise à jour de ${table}`, { type: 'error' })
      return false
    }
  }, [notify])

  // Fetch initial data
  const fetchInitialData = useCallback(async () => {
    if (isAdmin) {
      const smicData = await fetchFromDb('current_smic', {
        select: 'amount',
        orderBy: 'created_at',
        limit: 1
      })
      setSettings(prev => ({ ...prev, smicHoraire: smicData?.[0] }))
    } else {
      const [paieValidatedData, smsData, contactsData] = await Promise.all([
        fetchFromDb('paie_validation', {
          eqField: 'center_id',
          eqValue: identity?.center?.id
        }),
        fetchFromDb('sms_price', {
          eqField: 'center_id',
          eqValue: identity?.center?.id
        }),
        fetchFromDb('paie_createdContacts', {
          eqField: 'center_id',
          eqValue: identity?.center?.id
        })
      ])
      setSettings(prev => ({
        ...prev,
        paieValidatedData: paieValidatedData?.[0],
        smsData: smsData?.[0],
        createdContactsData: contactsData?.[0]
      }))
    }
  }, [fetchFromDb, isAdmin, identity?.center?.id])

  useEffect(() => {
    fetchInitialData()
  }, [fetchInitialData])

  // Handlers
  const handleSubmitSMIC = async (values) => {
    const success = await upsertData('current_smic', { 
      amount: values.amount,
      center_id: identity?.center?.id 
    })
    
    if (success) {
      await fetchInitialData()
      notify('Montant du SMIC horaire mis à jour', { type: 'success' })
    }
  }

  const handleSubmitSettings = async (values) => {
    const formatValue = (value) => {
      if (typeof value === 'number') return value
      if (typeof value === 'boolean') return value
      return value?.replace(',', '.') ?? null
    }

    const paieValidatedSuccess = await upsertData(
      'paie_validation',
      { 
        validated: formatValue(values.paieValidated),
        center_id: identity?.center?.id
      },
      settings.paieValidatedData?.id
    )

    const smsSuccess = await upsertData(
      'sms_price',
      { 
        amount: formatValue(values.amountSMS),
        center_id: identity?.center?.id
      },
      settings.smsData?.id
    )

    const contactsSuccess = await upsertData(
      'paie_createdContacts',
      {
        amount: formatValue(values.amountPerNewContact),
        perContacts: formatValue(values.perNewContact),
        center_id: identity?.center?.id
      },
      settings.createdContactsData?.id
    )

    if (paieValidatedSuccess && smsSuccess && contactsSuccess) {
      await fetchInitialData()
      notify('Réglages mis à jour avec succès', { type: 'success' })
    }
  }

  return (
    <>
      <Typography variant="body1" mb={4}>
        En modifiant un montant, celui-ci sera automatiquement appliqué aux <i>futures</i> lignes de paie.
      </Typography>

      {isAdmin ? (
        <CardWithIcon
          to="/leads"
          icon={EuroIcon}
          title="Montant du SMIC horaire"
          subtitle={value}
          color="#ffbe4f"
        >
          <Box p={4}>
            <Typography variant="h4" gutterBottom>
              {settings.smicHoraire?.amount} €
            </Typography>

            <SimpleForm
              onSubmit={handleSubmitSMIC}
              defaultValues={{ amount: '' }}
              toolbar={false}
            >
              <TextInput 
                source="amount" 
                label="Nouveau montant horaire BRUT" 
                variant="outlined" 
              />
              <SaveButton />
            </SimpleForm>
          </Box>
        </CardWithIcon>
      ) : (
        <Stack spacing={2} mt={2} direction="row">
          <CardWithIcon
            icon={EuroIcon}
            title="Réglage des montants aloués aux paies"
            subtitle={value}
            color="primary.light"
          >
            <Box p={4}>
              <SimpleForm
                onSubmit={handleSubmitSettings}
                defaultValues={{
                  paieValidated: settings.paieValidatedData?.validated ?? null,
                  amountSMS: settings.smsData?.amount ?? null,
                  amountPerNewContact: settings.createdContactsData?.amount ?? null,
                  perNewContact: settings.createdContactsData?.perContacts ?? null,
                }}
                toolbar={false}
              >
                <Typography variant="h6" gutterBottom>
                  Clôturer les paies
                </Typography>
                <BooleanInput
                  source="paieValidated"
                  label="Clôturer pour le mois en cours (désactivé le prochain 15 du mois)"
                  variant="outlined"
                />

                <Typography variant="h6" gutterBottom>
                  Conversations SMS
                </Typography>
                <TextInput 
                  source="amountSMS"
                  label="Montant payé pour un SMS considéré valide"
                  variant="outlined"
                />

                <Typography variant="h6" mt={2} gutterBottom>
                  Nouveaux contacts
                </Typography>

                <Grid2 container spacing={1}>
                  <Grid2 size={{ xs: 12, md: 5 }}>
                    <TextInput 
                      source="amountPerNewContact"
                      label="Montant payé"
                      variant="outlined"
                    />
                  </Grid2>

                  <Grid2 size={{ xs: 12, md: 2 }} sx={{ textAlign: 'center' }}>
                    <Typography variant="body1" mt={2}>
                      tous les
                    </Typography>
                  </Grid2>

                  <Grid2 size={{ xs: 12, md: 5 }}>
                    <TextInput 
                      source="perNewContact"
                      label="X nouveaux contacts"
                      variant="outlined"
                    />
                  </Grid2>
                </Grid2>
                
                <SaveButton />
              </SimpleForm>
            </Box>
          </CardWithIcon>
        </Stack>
      )}
    </>
  )
}

export default PaieSettings