import { supabaseClient } from '../supabase';
import baseDataProvider from '../supabase/baseDataProvider';

const inboundContactsDataProvider = {
  ...baseDataProvider,
  getList: async (resource, params) => {
    let result = null

    if (params.filter.center_id && params.filter.center_id === 'admin') {
      delete params.filter.center_id;
    }

    result = await baseDataProvider.getList(resource, params);

    return result;
  },
  deleteMany: async (resource, params) => {
    const chunkSize = 100;
    const chunks = [];
    for (let i = 0; i < params.ids.length; i += chunkSize) {
      chunks.push(params.ids.slice(i, i + chunkSize));
    }
    
    const errors = [];
    
    for (const chunk of chunks) {
      const { data, error } = await supabaseClient
        .from(resource)
        .delete()
        .in('id', chunk);

      if (error) {
        errors.push(error);
      }
    }

    if (errors.length > 0) {
      throw new Error(`Errors occurred in deleteMany: ${errors.map(e => e.message).join(', ')}`);
    }

    return { data: params.ids };
  },
};

export const dataProvider = inboundContactsDataProvider;