import { useEffect, useState } from 'react'
import { useGetIdentity, useRefresh } from 'react-admin'
import { FormControl, Select as MUIselect, InputLabel, MenuItem, Stack, Container, Box, Tab, Tabs, Typography } from '@mui/material'

import TasksList from './TasksList'
import MyFilters from './MyFilters'
import EventsList from './EventsList'
import WeeklyLeads from './WeeklyLeads'
import PaieSettings from './PaieSettings'
import LeadsInscrits from './LeadsInscrits'
import { getRole } from '../../common/roles'
import DashboardHippocast from '../hippocast'
import { supabaseClient } from '../../supabase'
import fetchEvents from '../../common/fetchEvents'
import DashboardRespoPaies from './DashboardRespoPaies'

const CustomTabPanel = ({ children, value, index, ...other }) => (
  <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
    {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
  </div>
)

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

export default () => {
  const { identity } = useGetIdentity()
  const refresh = useRefresh()
  const isAdmin = identity?.role?.grade === getRole('admin')

  const today = new Date()

  const lastMonday = new Date()
  lastMonday.setHours(0, 0, 0, 0)
  lastMonday.setDate(lastMonday.getDate() - (lastMonday.getDay() + 6) % 7)

  const firstOfTheMonth = new Date()
  firstOfTheMonth.setHours(0, 0, 0, 0)
  firstOfTheMonth.setDate(1)

  const academicYearStart = new Date()
  if (today.getMonth() < 7) { // If before August (Jan - Jul), use last year's August
    academicYearStart.setFullYear(today.getFullYear() - 1, 7, 1) // August 1st of last year
  } else {
    academicYearStart.setFullYear(today.getFullYear(), 7, 1) // August 1st of this year
  }
  academicYearStart.setHours(0, 0, 0, 0)

  const [filteredDate, setFilteredDate] = useState(lastMonday.toISOString())
  const [dashboardData, setDashboardData] = useState({
    numberOfNewLeads: 0,
    numberOfLeadsInscrits: 0,
    tasksInfo: null,
    eventsInfo: null
  })
  const [value, setValue] = useState(0)

  useEffect(() => {
    if (!identity) return

    const fetchNewLeads = async () => {
      try {
        const { data, error } = await supabaseClient
          .from('leads_view')
          .select('id')
          .gte('created_at', filteredDate)

        if (error) throw error
        return data.length
      } catch (error) {
        console.error('Error fetching new leads:', error)
        return 0
      }
    }

    const fetchLeadsInscrits = async () => {
      try {
        const { data, error } = await supabaseClient
          .from('leads_view')
          .select('id')
          .gte('created_at', filteredDate)
          .neq('status_inscription_id', 'f200316c-8ffc-4a3c-bf01-4efe6aad8d81')

        if (error) throw error
        return data.length
      } catch (error) {
        console.error('Error fetching leads inscrits:', error)
        return 0
      }
    }

    const fetchTasksInfo = async () => {
      try {
        let query = supabaseClient
          .from('lead_tasks')
          .select('id, progress, lead: lead_id (center_id, current_year_id), task: task_id (name)')
          .eq('lead.current_year_id', process.env.REACT_APP_CURRENT_YEAR_ID)

        if (identity?.role?.grade !== getRole('admin')) {
          query = query.eq('lead.center_id', identity?.center?.id)
        }

        const { data, error } = await query
        if (error) throw error

        return data.filter(task => task.lead !== null && task.task !== null)
      } catch (error) {
        console.error('Error fetching tasks info:', error)
        return null
      }
    }

    const fetchAllEvents = async () => {
      try {
        const dataEvents = await fetchEvents(identity?.center?.id, identity, filteredDate, null, process.env.REACT_APP_CURRENT_YEAR_ID)
        return dataEvents.filter(
          event => event.type_id === process.env.REACT_APP_EVENT_TYPE_MISSIONLYCEE || event.type_id === process.env.REACT_APP_EVENT_TYPE_INTERVENTION
        )
      } catch (error) {
        console.error('Error fetching events:', error)
        return null
      }
    }

    const fetchAllData = async () => {
      const [newLeads, leadsInscrits, tasksInfo, eventsInfo] = await Promise.all([
        fetchNewLeads(),
        fetchLeadsInscrits(),
        fetchTasksInfo(),
        fetchAllEvents()
      ])

      setDashboardData({ numberOfNewLeads: newLeads, numberOfLeadsInscrits: leadsInscrits, tasksInfo, eventsInfo })
    }

    fetchAllData()
  }, [filteredDate, identity])

  return (
    <Container sx={{ mt: 4, fontSize: '1rem' }}>
      <Stack spacing={2} direction="row" flex="1" alignItems="center" justifyContent="space-between" sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>Mon tableau de bord</Typography>
        <Box>
          <Tabs value={value} onChange={(event, newValue) => setValue(newValue)}>
            <Tab label="Vue générale" {...a11yProps(0)} />
            <Tab label="Paies pubeurs" {...a11yProps(1)} />
            <Tab label="Mes filtres" {...a11yProps(2)} />
            <Tab label="Réglages paies" {...a11yProps(3)} />
            {isAdmin && <Tab label="Hippocast" {...a11yProps(4)} />}
          </Tabs>
        </Box>
      </Stack>

      <CustomTabPanel value={value} index={0}>
        <>
          <Stack spacing={2} direction="row" justifyContent="flex-end">
            <Box sx={{ width: '20%' }}>
              <FormControl variant="outlined" sx={{ m: 0.5, minWidth: '100%' }}>
                <InputLabel id="monthLabel">Voir depuis</InputLabel>
                <MUIselect
                  labelId="monthLabel"
                  value={filteredDate}
                  onChange={e => setFilteredDate(e.target.value)}
                  label="Voir depuis"
                >
                  <MenuItem value={lastMonday.toISOString()}>Cette semaine</MenuItem>
                  <MenuItem value={firstOfTheMonth.toISOString()}>Mois en cours</MenuItem>
                  <MenuItem value={academicYearStart.toISOString()}>Année en cours</MenuItem>
                </MUIselect>
              </FormControl>
            </Box>
          </Stack>

          <Stack spacing={2} mt={2} direction="row">
            <Stack spacing={2} mt={2} direction="column" sx={{ width: '33.3333%' }}>
              <WeeklyLeads value={dashboardData.numberOfNewLeads} />
              <LeadsInscrits value={dashboardData.numberOfLeadsInscrits} />
            </Stack>
            <TasksList tasksInfo={dashboardData.tasksInfo} />
            <EventsList eventsInfo={dashboardData.eventsInfo} date={filteredDate} />
          </Stack>
        </>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}><DashboardRespoPaies /></CustomTabPanel>
      <CustomTabPanel value={value} index={2}><Box sx={{ p: 4 }}><MyFilters /></Box></CustomTabPanel>
      <CustomTabPanel value={value} index={3}><Box sx={{ p: 4 }}><PaieSettings isAdmin={isAdmin} identity={identity} /></Box></CustomTabPanel>
      <CustomTabPanel value={value} index={4}><DashboardHippocast /></CustomTabPanel>
    </Container>
  )
}
