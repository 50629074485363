import { defaultTheme } from 'react-admin'
import { deepmerge } from '@mui/utils'

const baseColor = '#fefefe'
const complementaryLight = '#555'

const darkTheme = deepmerge(defaultTheme, {
  components: {
    RaDatagrid: {
      styleOverrides: {
        root: {
          '& .RaDatagrid-headerCell': {
            backgroundColor: complementaryLight,
            color: baseColor,
          },
        },
      },
    },
    RaCreateButton: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          backgroundColor: '#418c8f',
          color: baseColor,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&.RaFormDialogTitle-closeButton': {
            color: '#fefefe'
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: baseColor,
          '&.Mui-selected': {
            backgroundColor: '#555',
            color: '#fff',
          },
        },
      },
    },
    MuiGrid2: {
      styleOverrides: {
        container: {
          width: '100%'
        },
      },
    },
  },
  typography: {
    ...defaultTheme.typography,
    fontFamily: 'Poppins, Arial, sans-serif',
    h6: { fontWeight: 600, color: baseColor },
    h5: { fontWeight: 600, color: baseColor },
    h4: { fontWeight: 700, color: baseColor },
    h3: { fontWeight: 700, color: baseColor },
    h2: { fontWeight: 800, color: baseColor },
    h1: { fontWeight: 800, color: baseColor },
  },
  palette: {
    ...defaultTheme.palette,
    mode: 'dark',
    background: {
      default: '#121212',
      paper: '#1e1e1e',
    },
    text: {
      primary: baseColor,
      secondary: '#B0B0B0',
    },
    primary: {
      main: '#52acb0',
    },
    secondary: {
      main: '#86BAB8',
    },
    info: {
      main: baseColor,
    },
    ternary: {
      main: '#2C2C2C',
      light: '#555',
      dark: '#121212'
    },
    complementary: {
      main: '#cd9f87',
      light: complementaryLight,
      dark: '#fefefe',
    },
    tabs: {
      main: '#101010',
    },
    cards: {
      backgroundColor: {
        main: '#222',
      },
      title: {
        main: '#69b9bd',
      },
      contentBackgroundColor: {
        main: '#1e1e1e',
      },
    },
    gridList: {
      backgroundColor: complementaryLight,
      cards: {
        backgroundColor: '#242424',
        textColor: '#fefefe',
        textDate: '#ccc',
        icon: '#9FCBC9',
        chipBackgroundColor: '#666',
      }
    },
    filterPanel: {
      backgroundColor: '#333',
      color: '#efefef',
    },
    favourited: {
      by: '#c84637'
    },
  },
})

export default darkTheme
