import { supabaseClient } from '../supabase'
import baseDataProvider from '../supabase/baseDataProvider'
import { getItemFromLocalStorage } from '../common/LocalStorage'

const localCurrentYear = getItemFromLocalStorage('localCurrentYear')?.id || process.env.REACT_APP_CURRENT_YEAR_ID

function replaceSpacesWithUnderscores(obj) {
  if (typeof obj !== 'object') return obj

  const result = {}

  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      if (key === 'phone@like') {
        const adjustPhoneNumber = obj[key].replace(/\s/g, '')
        result[key] = adjustPhoneNumber
        continue
      }

      result[key] = obj[key].replace(/\s|,/g, '_')
    } else {
      result[key] = obj[key]
    }
  }
  
  return result
}

// Filtering logic
const filteringLogic = (filters, query) => {
  if ( ! filters || Object.keys(filters).length === 0 ) return query

  const reactAdminFilters = replaceSpacesWithUnderscores(filters)

  let queryWithFilters = query

  // Check if group filters are on and treat them too
  if ( filters.groupFilters === 'ok' ) {
    // fetch group filters from local storage
    const groupedFilteredValues = getItemFromLocalStorage('groupedFilteredValues') ?? []
    const areFiltersPaused = getItemFromLocalStorage('pauseFilters') ?? false
    const betweenGroupsCondition = getItemFromLocalStorage('betweenGroupsCondition') ?? 'or'

    queryWithFilters = applyGroupFilters(queryWithFilters, groupedFilteredValues, betweenGroupsCondition, areFiltersPaused)
  }
  
  // treat regular React-Admin filters
  Object.keys(reactAdminFilters).forEach((key) => {
    const filterValue = filters[key]
    if (key === 'groupFilters') return

    if (key.endsWith('@ilike')) {
      const fieldKey = key.replace('@ilike', '')
      queryWithFilters = queryWithFilters.ilike(fieldKey, `%${filterValue}%`)
    } else if (key === 'phone@like') {
      queryWithFilters = queryWithFilters.ilike('phone', `%${filterValue}%`)
    } else if (key === 'center_id') {
      if (filterValue === '00000000-0000-0000-0000-000000000000') {
        queryWithFilters = queryWithFilters.is('center_id', null)
      } else {
        queryWithFilters = queryWithFilters.eq('center_id', filterValue)
      }
    } else if (key === 'assigned_pubeur') {
      if (filterValue === '00000000-0000-0000-0000-000000000000') {
        queryWithFilters = queryWithFilters.is('assigned_pubeur', null)
      } else {
        queryWithFilters = queryWithFilters.eq('assigned_pubeur', filterValue)
      }
    } else if (key === 'favourited_by' || key === 'check_all_favourites') {
      if (key === 'check_all_favourites') {
        queryWithFilters = queryWithFilters.not('favourited_by', 'is', null)
      } else {
        const filterDetails = filters[key]['1']
        const filterId = filterDetails?.filterId?.replace(/[{}]/g, '').trim()

        if (filterId) {
          queryWithFilters = queryWithFilters.contains('favourited_by', `[{"id":"${filterId}","is_fav":true}]`)
        }
      }
    } else if (key === 'id' || key === 'assigned_pubeur') {
      queryWithFilters = queryWithFilters.eq(key, filterValue)
    } else if (key === 'stop_rgpd') {
      queryWithFilters = queryWithFilters.eq('stop_rgpd', false)
    }
  })

  return queryWithFilters
}

// Custom group filters: uses query.url.href to bypass filters not translated by Supabase
const applyGroupFilters = (query, groupedFilteredValues, betweenGroupsCondition, areFiltersPaused = false) => {
  if (!Array.isArray(groupedFilteredValues) || areFiltersPaused) return query

  let queryUrlHref = new URL(query.url.href) // Convert query object to URL for safe manipulation
  let regularQueries = [] // Stores all regular groups' queries
  let manualSelects = [] // Stores additional select modifications

  groupedFilteredValues.forEach(group => {
    const groupCondition = group.groupCondition.toLowerCase() // 'and' or 'or'
    const groupFilters = group.groupFilters
    let allRegularQueries = [] // Queries inside a group
    let allGroupSelects = [] // Select modifications inside a group

    // Extract event and qualification filters if present in the group filters
    const eventFilterKeys = Object.keys(groupFilters).filter(filterName => filterName.startsWith('event_ids_'))
    const qualificationFilterKeys = Object.keys(groupFilters).filter(filterName => filterName.startsWith('events_qualifications_'))
    const eventFilter = eventFilterKeys.length > 0 ? groupFilters[eventFilterKeys[0]] : null
    const qualificationFilter = qualificationFilterKeys.length > 0 ? groupFilters[qualificationFilterKeys[0]] : null
    const hasEventFilter = !!eventFilter // Boolean indicating if an event filter exists

    Object.keys(groupFilters).forEach(filterName => {
      const key = filterName.replace('_' + group.groupUniqueID, '')
      const filterOptions = groupFilters[filterName]

      if ( isNotBoolFilterKey(key) ) {
        const conditionType = getConditionType(key) // cs or eq
        const localQueries = handleQueries(key, filterOptions, conditionType, hasEventFilter, eventFilter, qualificationFilter)
        allRegularQueries.push(localQueries)
      } else {
        allRegularQueries.push(`${key}.is.${filterOptions[1].filterMethod}`)
      }
    })

    if (allGroupSelects.length > 0) {
      manualSelects.push(...allGroupSelects)
    }

    if (allRegularQueries.length > 0) {
      const groupQuery = `${groupCondition}(${allRegularQueries.join(',')})`
      regularQueries.push(groupQuery)
    }
  })

  if (regularQueries.length > 0) {
    const finalQuery = `${betweenGroupsCondition}=(${regularQueries.join(',')})`
    
    if (!areFiltersPaused) {
      queryUrlHref.search = queryUrlHref.search
        ? `${queryUrlHref.search}&${finalQuery}`
        : `?${finalQuery}`
    }
  }

  query.url.href = queryUrlHref.toString()
  return query
}

const handleQueries = (key, filterOptions, conditionType, hasEventFilter = false, eventFilter = null) => {
  let queries = []

  Object.keys(filterOptions).forEach(filterKey => {
    const filterMethod = filterOptions[filterKey].filterMethod
    let filterId = filterOptions[filterKey].filterId

    // Adjust key for qualifications filtering if there's an event filter
    if (key === 'events_qualifications' && hasEventFilter && eventFilter) {
      key = 'qualifications_for_events'
    }

    // store query
    let keyQuery
    if (filterId === '00000000-0000-0000-0000-000000000000') {
      // Handle null values
      keyQuery = filterMethod ? `${key}.is.null` : `not.and(${key}.is.null)`
    } else if (
      conditionType === 'cs' &&
      ['event_ids', 'event_types', 'events_qualifications','tags_ids'].includes(key)
    ) {
        keyQuery = filterMethod
          ? `${key}.cs.{${filterId}}`
          : `not.and(${key}.cs.{${filterId}})`
    } else {
      if ( key === 'tasks_info' ) {
        if (filterId === 0) {
          keyQuery = `or(tasks_info.cs.[{"progress":0}],tasks_info.cs.[{"progress":null}])`
        } else if (filterId === 33) {
          keyQuery = `and(not.and(tasks_info.cs.[{"progress":null}]),not.and(tasks_info.cs.[{"progress":0}]),not.and(tasks_info.cs.[{"progress":100}]))`
        } else if (filterId === 45) {
          keyQuery = `and(not.and(tasks_info.cs.[{"progress":null}]),not.and(tasks_info.cs.[{"progress":0}]),not.and(tasks_info.cs.[{"progress":100}]),tasks_info.cs.[{"is_waiting":true}])`
        } else if (filterId === 55) {
          keyQuery = `and(not.and(tasks_info.cs.[{"progress":null}]),not.and(tasks_info.cs.[{"progress":0}]),not.and(tasks_info.cs.[{"progress":100}]),tasks_info.cs.[{"is_waiting":false}])`
        } else if (filterId === 100) {
          keyQuery = `tasks_info.cs.[{"progress":100}]`
        } else {
          keyQuery = `tasks_info.cs.[{"id":"${filterId}"}]`
        }
 
        // Handle NOT keyQuery dynamically
        if (!filterMethod) {
          keyQuery = `not.and(${keyQuery})`
        }
      } else if (key === 'qualifications_for_events') {
        if (!hasEventFilter || !eventFilter) {
          return false // Ensure an event filter is provided
        }
      
        const eventFilterKey = Object.keys(eventFilter)[0] // Access the first key in eventFilter (e.g., "2")
        const eventFilterId = eventFilter[eventFilterKey]?.filterId // Get the event filterId
      
        if (!eventFilterId) {
          return false // If no valid event filterId, skip processing
        }
      
        // Construct the query to check if the qualification exists under the specified event
        keyQuery = filterMethod
          ? `qualifications_for_events->${eventFilterId}.cs.["${filterId}"]`
          : `not.and(qualifications_for_events->${eventFilterId}.cs.["${filterId}"])`
      } else if (key === 'status_inscription_id' ) {
        keyQuery = filterMethod
          ? `${key}.eq.${filterId}`
          : `or(not.and(${key}.eq.${filterId}),${key}.is.null)`
      } else {
        // Default equality check
        keyQuery = filterMethod
          ? `${key}.eq.${filterId}`
          : `not.and(${key}.eq.${filterId})`
      }
    }

    if ( keyQuery ) queries.push(keyQuery)
  })

  return queries.length > 0 ? queries.join(',') : null
}

const getConditionType = (key) => ['events_qualifications', 'event_ids', 'event_types', 'tags_ids'].includes(key) ? 'cs' : 'eq'

const BOOL_KEYS = new Set(['interested', 'not_interested', 'registered'])
const isNotBoolFilterKey = key => !BOOL_KEYS.has(key)

// custom dataProvider
const leadsDataProvider = {
  ...baseDataProvider,
  getList: async (resource, params, options = {}) => {
    const a = performance.now()

    const { filter, pagination, sort } = params
    const { page, perPage } = pagination
    const { field, order } = sort

    // get right resource based on current year
    if (localCurrentYear === process.env.REACT_APP_CURRENT_YEAR_ID) {
      resource = 'leads_view'
    } else if (localCurrentYear === 'e24834f3-d19a-4ad5-ad5d-85da26458f65') {
      resource = 'leads_view_2023'
    } else if (localCurrentYear === 'c46545ce-47f8-40d2-95b4-927cb6d73c01') {
      resource = 'leads_view_2022'
    }

    // base of the query
    let query = supabaseClient
      .from(resource)
      .select('*', { count: 'exact' })
      .range((page - 1) * perPage, page * perPage - 1)
      .order(field, { ascending: order === 'ASC' })

    // apply React-Admin filter and custom filters
    query = filteringLogic(filter, query)

    // console.log(query.url.href)
    
    const { data, error, count } = await query

    if (error) {
      console.error('Error fetching leads:', error)
      throw new Error(error.message)
    }

    const ab = performance.now()
    console.log('Returning '+ count +' leads in ' + (ab - a) + ' ms.')

    return {
      data: data.map((lead) => ({ ...lead, id: lead.id })),
      total: count || 0,
    }
  },
  
  deleteMany: async (resource, params) => {
    const chunkSize = 100
    const chunks = []
    for (let i = 0; i < params.ids.length; i += chunkSize) {
      chunks.push(params.ids.slice(i, i + chunkSize))
    }

    const errors = []
    
    for (const chunk of chunks) {
      const { error } = await supabaseClient
        .from(resource)
        .delete()
        .in('id', chunk)

      if (error) {
        errors.push(error)
      }
    }

    if (errors.length > 0) {
      throw new Error(`Errors occurred in deleteMany: ${errors.map(e => e.message).join(', ')}`)
    }

    return { data: params.ids }
  }
}

export const dataProvider = leadsDataProvider